<template>
  <section class="coupon-setting" :v-loading="loading">
    <PageTitle
      title="營業時間設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <section class="section-block">
      <SectionTitle title="分店營業時間" fontSize="18" @edit="showDialog = true" />
      <el-form v-if="showSpecialTime" label-position="left" label-width="79px">
        <el-form-item v-for="item in weeksOptions" :key="item.value" :label="item.label">
          <p v-if="formatTimeRange(item.value).length == 0">休息日</p>
          <template v-else>
            <p v-for="(item,index) in formatTimeRange(item.value)" :key="index">{{ item.timeStart }} ~ {{ item.timeEnd }} </p>
          </template>
        </el-form-item>
      </el-form>
      <div v-else class="text-[#5E5E5E] text-[14px]">24 小時營業</div>
    </section>
    <el-dialog
      title="編輯分店營業時間"
      :visible="showDialog"
      width="670px"
      :close-on-click-modal="false"
      @close="showDialog = false"
    >
      <el-form ref="formBusinessRef" :model="formData" :rules="formRules">
        <el-radio-group v-model="formData.mode">
          <el-radio v-for="item in businessTimeOptions" :key="item.value" :label="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
        <div v-if="formData.mode === 'CustomWeek'" class="special-time">
          <hr class="mt-[20px] mb-[20px]">
          <p class="content">備註：</p>
          <ol>
            <li>未設定的時段視同休息日</li>
            <li>如需設定休息時段（例如午休），可新增兩則營業時段並選擇相同星期</li>
          </ol>
          <AddTimes v-if="formData.customWeekConfigs" title="新增營業時段" :customWeekConfigs="formData.customWeekConfigs" isWeek timeStep="00:30" />
        </div>
      </el-form>
      <div slot="footer">
        <el-button plain @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="confirm">確定</el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import AddTimes from './components/AddTimes.vue'
import { weeksOptions, businessTimeOptions } from '@/config/date'
import { noEmptyWeekRangeInterval } from '@/validation'
import { isArray, get } from 'lodash'
import store from '@/store'
import { GetShopBusinessTimeConfig, UpdateShopBusinessTimeConfig } from '@/api/shopBusinessTime'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'ShopBusinessTimeSettings',
  components: { AddTimes },
  setup (props) {
    const loading = ref(false)
    const formData = reactive({
      mode: null,
      customWeekConfigs: [],
    })
    const defaultData = ref({
      mode: null,
      customWeekConfigs: [],
    })
    const formBusinessRef = ref(null)
    const formRules = computed(() => {
      const rules = {
        customWeekConfigs: [noEmptyWeekRangeInterval()],
      }
      return rules
    })
    const showDialog = ref(false)
    const shopId = computed(() => store.getters.shop)
    const showSpecialTime = computed(() => defaultData.value.mode === 'CustomWeek')

    const confirm = async () => {
      const pass = await formUtils.checkForm(formBusinessRef.value)
      console.log(pass)
      if (!pass) return

      await updateShopBusinessTimeConfig()
      showDialog.value = false
    }

    const getShopBusinessTimeConfig = async () => {
      loading.value = true
      const [res, err] = await GetShopBusinessTimeConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      formData.mode = get(res, 'mode')

      const customWeekConfigs = get(res, 'customWeekConfigs')
      const newWeeks = customWeekConfigs.map(item => {
        const { week, timeStart, timeEnd } = item
        return {
          week: week,
          timeStart: minutesToTime(timeStart),
          timeEnd: minutesToTime(timeEnd),
        }
      })
      formData.customWeekConfigs = newWeeks || [{ week: null, timeStart: null, timeEnd: null }]
      defaultData.value = JSON.parse(JSON.stringify(formData))
    }
    const updateShopBusinessTimeConfig = async () => {
      loading.value = true
      const { customWeekConfigs, mode } = formData
      const newWeeks = []
      customWeekConfigs.forEach(item => {
        const { week, timeStart, timeEnd } = item
        // if (week !== null && timeStart !== '' && timeEnd !== '') {
        newWeeks.push({
          week: week,
          timeStart: timeToMinutes(timeStart),
          timeEnd: timeToMinutes(timeEnd),
        })
        // }
      })
      const [, err] = await UpdateShopBusinessTimeConfig({
        shopId: shopId.value,
        mode: mode,
        customWeekConfigs: mergeTimeIntervals(newWeeks),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功!')
      getShopBusinessTimeConfig()
    }
    const formatTimeRange = (value) => {
      const customWeekConfigs = get(defaultData.value, 'customWeekConfigs')
      const newArray = customWeekConfigs.filter(item => {
        return item.week === value
      })
      return newArray.sort()
    }
    const timeToMinutes = (time) => {
      const [hours, minutes] = time.split(':')
      return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
    }
    const minutesToTime = (minutes) => {
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60

      const formattedHours = hours.toString().padStart(2, '0')
      const formattedMinutes = remainingMinutes.toString().padStart(2, '0')

      return `${formattedHours}:${formattedMinutes}`
    }
    const mergeTimeIntervals = (intervals) => {
      const mergedIntervals = []
      const sortedIntervals = intervals.sort((a, b) => a.week - b.week || a.timeStart - b.timeStart)

      let currentInterval = sortedIntervals[0]

      for (let i = 1; i < sortedIntervals.length; i++) {
        const nextInterval = sortedIntervals[i]

        if (currentInterval.week === nextInterval.week && currentInterval.timeEnd >= nextInterval.timeStart) {
          currentInterval.timeEnd = Math.max(currentInterval.timeEnd, nextInterval.timeEnd)
        } else {
          mergedIntervals.push(currentInterval)
          currentInterval = nextInterval
        }
      }
      mergedIntervals.push(currentInterval)
      const [result] = mergedIntervals
      if (result === undefined) return []
      else return mergedIntervals
    }

    onMounted(() => {
      getShopBusinessTimeConfig()
    })
    return {
      loading,
      formData,
      showDialog,
      confirm,
      showSpecialTime,
      weeksOptions,
      businessTimeOptions,
      isArray,
      formatTimeRange,
      formRules,
      formBusinessRef,
      defaultData,
    }
  },
})
</script>
<style lang="postcss" scoped>
::v-deep .el-select .el-input {
  @apply w-auto
}
ol {
  list-style: demical;
  padding-left: 16px;
}
::v-deep .el-form-item__label, ::v-deep .el-form-item__content {
  color: var(--info);
  line-height: 20.27px;
}

::v-deep .el-radio {
  display: inline-flex;
  align-items: center;
}

::v-deep .el-radio__inner {
  width: 20px;
  height: 20px;
}

::v-deep .el-radio-group .el-radio__inner::after {
  width: 15px;
  height: 15px;
  border-width: 3px;
}
</style>
