var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"mt-[20px] mb-2"},[_c('el-button',{attrs:{"data-cy":"add-period-btn","plain":""},on:{"click":_vm.addTimes}},[_vm._v(_vm._s(_vm.title))])],1),_c('el-form-item',{attrs:{"prop":"customWeekConfigs","label-width":"0"}},_vm._l((_vm.data),function(item,index){return _c('div',{key:index + item.timeStart,staticClass:"flex align-items-stretch mb-2"},[(_vm.isWeek)?_c('el-select',{attrs:{"placeholder":"選擇星期"},model:{value:(item.week),callback:function ($$v) {_vm.$set(item, "week", $$v)},expression:"item.week"}},_vm._l((_vm.weeksOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1):_vm._e(),_c('el-time-select',{staticClass:"w-full ml-2",attrs:{"data-cy":"start-time-select","placeholder":"起始時間","picker-options":{
          start: '00:00',
          step: _vm.timeStep,
          end: '24:00',
        }},model:{value:(item.timeStart),callback:function ($$v) {_vm.$set(item, "timeStart", $$v)},expression:"item.timeStart"}}),_c('el-time-select',{staticClass:"w-full ml-2",attrs:{"data-cy":"end-time-select","placeholder":"結束時間","picker-options":{
          start: item.timeStart,
          step: _vm.timeStep,
          end: '24:00',
          minTime: item.timeStart,
        }},model:{value:(item.timeEnd),callback:function ($$v) {_vm.$set(item, "timeEnd", $$v)},expression:"item.timeEnd"}}),_c('el-button',{staticClass:"p-2 ml-2",attrs:{"icon":"el-icon-close"},on:{"click":function($event){return _vm.remove(index)}}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }